import React from 'react'
import { graphql } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import SEO from "../components/seo"
import Layout from '../components/layout'
import ArtistNav from '../components/artistNavBar'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES} from "@contentful/rich-text-types"
import './artist-press.css'


class ArtistPressTemplate extends React.Component {
  
  render() {

    const options = {
      renderNode: {
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          return <a href={node.data.target.file.url} className="pdf-link" target='blank'  rel="noopener noreferrer">{children}</a>
        }
    }
  }

    const artist = this.props.data.contentfulArtist
    const press = artist.press === null? <h1>Press Coming Soon </h1>: renderRichText(artist.press, options)

    

    return (
      <Layout location={this.props.location}>
        <SEO 
          title={`${artist.name}`}
          description={`Selected press by artist ${artist.name}`}
        />
        <div style={{ background: '#fff' }}>
          <div className="artistHeader">{artist.name}</div>
            <ArtistNav slug={artist.slug}/>
            <div className="richtext-cont">{press}</div>
        </div>
      </Layout>
    )
  }
}

export default ArtistPressTemplate

export const pageQuery = graphql`
  query artistAndArtist($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
        name
        slug
        press {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              file {
                url
                contentType
              }
            }
          } 
        }
    }
  }
`

